@import '../../styles/style.scss';
.our-projects {
  .content {
    .picture {
      width: 600px;
    }
    .box-margin{
        margin-bottom: 100px;
    }
    .box-2 {
      .title {
        letter-spacing: 0px;
        color: #000000;
        text-transform: capitalize;
        opacity: 1;
        @include font(30, 100, 70, 140);
        font-family:  $poppin ;
        font-weight: 500;
      }
      .view-button {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 3px;
        opacity: 0.72;
        color: #707070;
        text-decoration: none;
        padding: 2px 5px;
        line-height: 22px;
      }
    }
    .box-right{
        margin-left: -50px;
    }
    .box-left{
        margin-right: -50px;
    }
  }
}
@media screen and (max-width: 1200px) {
    .our-projects {
        .content {
          .picture {
            width: 50%;
          }
        }
    }
  }
  @media screen and (max-width: 768px) {
    .our-projects {
        .content {
            .box-2{
                .title{
                    line-height: unset;
                }
            }
          .picture {
            width: 100%;
          }
          .box-left, .box-right{
              margin: 0;
          }
          .box-margin{
              margin-bottom: 40px;
          }
        }
    }
  }