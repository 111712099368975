@import "../../styles/style.scss";
.section-1 {
  background-image: url("../../assets/images/backgroundmap.svg");
  min-height: 600px;
  background-size: 450px;
  background-repeat: no-repeat;
  background-position-x: right;
  .banner {
    .house {
      width: 500px;
    }
    .banner-text {
      .heading {
        letter-spacing: 0px;
        font-family: Poppins;
        font-weight: 600;
        color: #343436;
        opacity: 1;
        @include font(40, 70, 70, 119);
        text-align: start;
      }
      .sub-heading {
        font-family: "Roboto Mono", monospace;
        letter-spacing: 0px;
        color: #8f8f8f;
        opacity: 1;
        width: fit-content;
        @include font(20, 40, 30, 56);
        font-weight: 100;
      }
    }
  }
  .banner-down {
    width: 100%;
  }
}
@media screen and (max-width: 1400px) {
  .section-1 {
    .banner {
      .house {
        width: 40%;
      }
      .banner-text {
        .heading {
          @include font(30, 70, 70, 119);
        }
    }
    }
  }
}

@media screen and (max-width: 768px) {
  .section-1 {
    min-height: unset;
    .banner {
      .house {
        width: 60%;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .section-1 {
    .banner {
      .house {
        width: 80%;
      }
      .banner-text {
        .heading {
          @include font(20, 70, 40, 90);
        }
      }
    }
  }
}
