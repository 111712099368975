@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap");
@import "../../styles/style.scss";
.header-main {
  .header-text {
    margin-left: 20px;
    cursor: pointer;
    // font: normal normal normal 25px/38px Poppins;
    font-family: Poppins;
    letter-spacing: 0px;
    color: #303435b8;
    opacity: 1;
    @include font(14, 24, 28, 38);
    line-height: unset;
  }

  .cool-link {
    display: inline-block;
    color: #727477;
    text-decoration: none;
  }
  .cool-link::before {
    content: "";
    display: flex;
    width: 5px;
    height: 5px;
    border-radius: 4px;
    background-color: #ffffff;
    margin-left: auto;
    margin-bottom: -8px;
    margin-right: -4px;
  }
  .cool-link::after {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    margin-top: -8px;
    background-color: transparent;
    transition: background-color 0.3s;
  }

  .cool-link:hover::after {
    background-color: $yellow;
  }
  .activated {
    color: #343436;
    text-decoration: none;
  }
  .activated::before {
    background-color: $yellow;
  }
  .activated:hover::after {
    background-color: transparent;
  }
}
.header-drop {
  position: fixed;
  overflow: hidden;
  visibility: hidden;

  transform: translate(0%, -100%);
  transition: all ease-in-out 0.6s;
  opacity: 1;
  visibility: inherit;
  z-index: 999;
  background-color: #000;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.header-drop-open {
  transform: translate(0px, 0px);
  opacity: 1;
  visibility: inherit;
}
.header-drop-nav {
    padding: $common-padding;
  }
  .drop-link{
    font-size: calc(18.33333px + 2.13333vw);
    color: #ffffff;
    font-weight: 100;
    width: fit-content;
    padding: $common-padding;
    text-decoration: none;
  }
@keyframes openTop {
  0% {
    transform: translate(0%, -100%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}
@media screen and (max-width: 768px) {
        .logo-nav{
            width: 120px;
        }
  }
  @media screen and (max-width: 600px) {
        .logo-nav{
            width: 80px;
        }
  }