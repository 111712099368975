@import "../../styles/style.scss";
.contact-us {
  .heading {
    font: normal normal 600 79px/119px Poppins;
    letter-spacing: 0px;
    color: #343436;
    text-transform: uppercase;
    @include font(28, 79, 40, 119);
  }
  .text {
    font: normal normal normal 40px/56px Orator Std;
    letter-spacing: 0px;
    color: #8f8f8f;
    opacity: 1;
    @include font(18, 40, 18, 56);
    font-family: "orator-std";
  }
  .action {
    margin: 50px 0;
    .call-button {
      text-decoration: none;
      border-radius: 5px;
      border: none;
      outline: none;
      padding: 14px 26px;
      font: normal normal normal 20px/30px Poppins;
      @include font(12, 18, 14, 24);
      letter-spacing: 0px;
      opacity: 1;
    }
    .phone {
      width: 13px;
      padding: 3px;
      box-sizing: content-box;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.4);
    }
    .call-button-yellow {
      background-color: #ffcd40;
      color: #2f2f2f;
    }
    .call-button-black {
      background-color: #121212;
      color: #c5c5c5;
      margin-left: 30px;
    }
  }
}
