@import "../../styles/style.scss";
.project {
    margin-bottom: 80px;
    .heading {
        text-align: left;
        font: normal normal 600 79px/119px Poppins;
        font-family: "Poppins", sans-serif;
        letter-spacing: 0px;
        color: #343436;
        text-transform: uppercase;
        opacity: 1;
        margin-bottom: 20px;
        @include font(28, 79, 40, 119);
        @media screen and (max-width : 576px){
            width : 100%;
        }
    }
    .main {
        width : 100%;
    }
    .img {
        width : 70%;
        margin-bottom: 20px;
    }
    .more-content {
        font-size: 18px;
        margin-top: 60px;
    }
}