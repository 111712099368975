
@mixin  heading($min-font-size, $max-font-size, $min-line-height, $max-line-height) {
  font-size: calc(#{$min-font-size}px + (#{$max-font-size} - #{$min-font-size}) * ((100vw - 300px) / (2400 - 300)));
  line-height: calc(#{$min-line-height}px + (#{$max-line-height} - #{$min-line-height}) * ((100vw - 300px) / (2400 - 300)));
}
@font-face {
  font-family: "orator-std";
  src: url('./assets/font/Orator-Std-Font.otf');
}
.common-heading {
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  width: fit-content;
  font-family: Poppins;
  @include heading(18, 27, 28, 40);
  font-weight: 500;


}
.common-heading::after {
  content: "";
  display: block;
  width: 100%;
  margin-top: -12px;
  background: linear-gradient(to top, #ffcd4270 0%, #f7aa028c 100%);
  transition: height 0.3s;
  height: 5px;
  margin-left: 2px;
}

.common-padding{
  padding: 0 5%;
}
.common-margin-top{
  margin-top: 100px;
}
.max-width-50{
  max-width: 50%;
}
@media screen and (min-width: 1400px) {
  .common-padding{
    padding: 0 10%;
  }
}
@media screen and (max-width: 767px) {
  .common-margin-top{
      margin-top: 50px;
  }
}