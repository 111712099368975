@import "../../styles/style.scss";
.footer {
  background: transparent url("../../assets/images/footer.svg") 0% 0% no-repeat
    padding-box;
  //   background-color: #2f2f2f;
  background-color: #000000eb;
  background-size: cover;
  padding: 10px 0;
  //   opacity: 0.15;
  .footer-content {
    padding-right: 0;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .head {
    max-width: 660px;
  }
  .lets-friend {
    font: normal normal 600 70px/100px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
    @include font(30, 70, 60, 100);
  }
  .sub-text {
    font: normal normal normal 22px/30px Lato;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 0.75;
    @include font(16, 22, 20, 30);
    padding-bottom: 20px;
  }
  .mt-70 {
    margin-top: 70px;
  }
  .time-box {
    background: #404141 0% 0% no-repeat padding-box;
    border: 1px solid #404141;
    border-radius: 5px 0px 0px 5px;
    margin-left: auto;
    padding: 20px;
    height: fit-content;
    min-width: 240px;

    .text {
      font: normal normal bold 28px/40px Lato;
      letter-spacing: 0px;
      color: #ffffff;
      text-transform: uppercase;
      @include font(18, 28, 30, 40);
    }
    .time {
      font: normal normal medium 25px/40px Lato;
      letter-spacing: 0px;
      color: #c4c2c6;
      @include font(12, 24, 30, 40);
    }
  }
  .social-icons {
    .social-box {
      width: 75px;
      height: 170px;
      direction: rtl;
      margin-left: 100px;
      box-shadow: 0px 3px 6px #00000029;
      border: 2px solid #606060;
      border-radius: 5px;
      padding: 20px 10px;
      cursor: pointer;
      margin-bottom: 50px;
      .social-img {
        width: 40px;
        margin: 0 auto;
        transition: all ease-in-out 0.3s;
      }
      .social-text {
        font: normal normal 600 24px/40px Poppins;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: capitalize;
        opacity: 1;
        margin-top: 25px;
        @include font(14, 24, 30, 40);
      }
    }
    .social-box:hover {
      .social-img {
        transform: scale(1.1);
      }
    }
  }
  .right-reserved{
    font: normal normal normal 12px/20px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    opacity: 1;
    margin: 40px 0 0;
  }
}
@media screen and (max-width: 768px) {
  .footer {
    .sub-text {
      margin-bottom: 20px;
    }
    .mt-md{
        margin-top: 50px;
    }
    .social-icons{
        .social-box{
            .social-text{
                margin-right: 20px;
            }
        }
    }
    .head{
        padding-right: 20px;
    }
  }
}
