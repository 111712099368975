@import '../../styles/style.scss';

.about {
    margin-bottom: 80px;
    .heading {
        text-align: left;
        font: normal normal 600 79px/119px Poppins;
        font-family: "Poppins", sans-serif;
        letter-spacing: 0px;
        color: #343436;
        text-transform: uppercase;
        opacity: 1;
        @include font(28, 79, 40, 119);
        @media screen and (max-width : 576px){
            width : 100%;
        }
    }

    .content {
        .content-word {
            display: flex;
            flex-direction: column;
            width: 50%;
            @media screen and (max-width : 576px){
                width : 100%;
            }
        }
    
        .article-1 {
            margin-bottom : 30px;
            text-align: left;
            font: normal normal normal 28px/40px Lato;
            letter-spacing: 0px;
            color: #000000;
            @include font(16, 28, 28, 40);
            .name {
                font-weight: 900;
                text-transform: uppercase;
            }
        }
    
        .article-2 {
            text-align: left;
            letter-spacing: 0px;
            color: #000000;
    
            .article-2-heading {
                font: normal normal medium 29px/43px Poppins;
                text-transform: capitalize;
                opacity: 1;
                @include font(16, 29, 28, 43);
            }
    
            .article-2-content { 
                font: normal normal medium 28px/40px Lato;
                opacity: 0.6;
                @include font(20, 28, 26, 40);
            }

        }

        .content-img {
            width: 40%;
            border-radius: 19px;
            opacity: 1;
            margin-bottom: 30px;
            @media screen and (max-width : 576px){
                width : 100%;
            }
        }
    }

}