@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap");
@import "../../styles/style.scss";
.about-me {
  .content {
    padding: 40px;
    padding-right: 50px;
  }
  .text {
    text-align: left;
    font-family: "Lato", sans-serif;
    letter-spacing: 0px;
    color: #000000;
    @include font(16, 20, 18, 24);
    line-height: unset;
    font-weight: 500;
  }
  .bolder {
    font-weight: 900;
  }
  .sub-head {
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
    @include font(18, 24, 20, 30);
    opacity: 1;
  }
  .bottom-text {
    opacity: 0.6;
    color: #000000;
  }
  .box {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    opacity: 1;
  }
  .profile-img {
    border-radius: 10px;
    width: 390px;
    margin: -40px -40px 0 0;
  }
}
@media screen and (max-width: 1200px) {
 .about-me{
     .profile-img{
         width: 300px;
     }
 }
}
@media screen and (max-width: 767px) {
    .about-me{
        .profile-img{
            width: 100%;
            margin: 0;
        }
        .content{
            padding: 20px;
        }
    }
   }