$common-padding: 0 5%;
$poppin:"Poppins", sans-serif;
@mixin  font($min-font-size, $max-font-size, $min-line-height, $max-line-height) {
    font-size: calc(#{$min-font-size}px + (#{$max-font-size} - #{$min-font-size}) * ((100vw - 300px) / (2400 - 300)));
    line-height: calc(#{$min-line-height}px + (#{$max-line-height} - #{$min-line-height}) * ((100vw - 300px) / (2400 - 300)));
}


// colors
$yellow:#ffcd40;
